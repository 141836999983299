
.footer {
    padding: 20px 0 0 0;
    color: #333;
    font-family: Arial, sans-serif;
    background: linear-gradient(0deg, #bdbdbd, #dbdbdb);
}

.footer-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 2px;
}

.footer-logo-section {
    display: flex;
    flex-direction: column;
}

.footer-logo {
    width: 180px;
}

.footer-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer-icon-text {
    display: flex;
    align-items: center; 
    gap: 15px;
}

    .footer-icon-text i {
        font-size: 2.5rem; /* Uniformizando o tamanho dos ícones */
        color: #dc920b;
        display: flex;
        align-items: center;
        justify-content: center; /* Centraliza o ícone dentro de seu contêiner */
        width: 3rem; /* Define uma largura uniforme */
        height: 3rem; /* Define uma altura uniforme */
    }

    .footer-icon-text a {
        font-size: 0.9rem; /* Ajuste para manter o texto consistente */
        color: inherit;
        text-decoration: none;
    }

        .footer-icon-text a:hover {
            text-decoration: underline;
        }

.footer-column h3 {
    font-size: 1.1rem;
    margin: 0;
    font-weight: 600;
}

.footer-column p {
    margin: 0;
    font-size: 0.8rem;
}

.footer-bottom {
    text-align: center;
    padding: 12px 0 1px 0;
    background: linear-gradient(0deg, #9d9d9d, #c6c3c3);
}

    .footer-bottom p {
        margin-bottom: 5px;
        font-size: 0.7rem;
        font-weight: 600;
    }

.footer-link {
    color: inherit;
    text-decoration: none;
}

    .footer-link:hover {
        text-decoration: underline;
    }

    @media (max-width: 768px) {
    .footer {
        padding: 0;
    }

    .footer-container {
        background: linear-gradient(0deg, #868686, #555454);
        flex-direction: column;
        align-items: center;
        text-align: left;
        gap: 5px;
        color: white;
        padding: 20px;
    }

        .footer-column {
            width: 100%;
            align-items: flex-start;
        }

    .footer-bottom {
        background: linear-gradient(0deg, #555454, #868686);
        color: white;
        text-align: center;
    }

    .footer-bottom p {
        font-size: 0.7rem;
        font-weight: 600;
    }
}
