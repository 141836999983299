.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.card-endereco-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;
}

.card-endereco {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 16px;
    padding: 16px;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
    width: calc(100% - 32px);
    box-sizing: border-box;
    position: relative;
}

.card-endereco:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #f0f0f0;
}

.card-endereco-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.card-endereco-header .title {
    font-size: 18px;
    font-weight: bold;
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-endereco-content {
    margin-top: 8px;
}

.card-endereco-content p {
    margin: 4px 0;
}

.card-endereco-actions {
    position: absolute;
    top: 16px;
    right: 16px;
}

.card-endereco-actions .btn-delete {
    background: none;
    border: none;
    color: #dc3545;
    cursor: pointer;
    font-size: 1.2em;
}

.card-endereco-actions .btn-delete:hover {
    color: #c82333;
}
.endereco-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.endereco-header .btn-primary {
    margin-left: auto;
}
