.endereco-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

@media (max-width: 768px) {
    .form-group {
        min-width: 100%;
    }
}

.form-group-inline {
    display: flex;
    align-items: center;
    position: relative;
}

.cep-input-wrapper {
    position: relative;
    display: flex;
    width: 100%;
}

    .cep-input-wrapper .form-control {
        padding-right: 40px; /* Espaço para o botão de busca */
        flex: 1;
    }

.cep-search-btn {
    position: absolute;
    right: 5px;
    top: 55%;
    transform: translateY(-50%);
    border-radius: 3px;
    color: #747474;
    font-size: 0.8rem;
    border: none;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 35px !important;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 35%;
    margin: 0 !important;
}

    .cep-search-btn:hover {
        background-color: #a5cdfa;
    }

    .cep-search-btn .fa-search {
        color: #007bff;
    }

    .cep-search-btn:hover .fa-search {
        color: #0056b3;
    }

@media (max-width: 768px) {
    .form-group {
        min-width: 100%;
    }
}

