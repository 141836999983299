.controle-cliente-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    background: linear-gradient(to left, #dfdfe099, #b9b7b6);
    color: white;
    padding: 10px;
}

.controle-cliente-container {
    padding: 10px 20px;
}

.header-buttons {
    display: flex;
    align-items: center;
    gap: 10px;
}

.card-cliente-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 10px;
    justify-content: center;
    margin: 0 auto;
    padding: 10px;
}

.card-cliente {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 10px;
    transition: box-shadow 0.3s ease, background-color 0.3s ease;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
}

    .card-cliente:hover {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        background-color: #f0f0f0;
    }

.card-cliente-header {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

    .card-cliente-header .title {
        font-size: 18px;
        font-weight: bold;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

.card-cliente-content {
    margin-top: 8px;
    flex-grow: 1;
}

    .card-cliente-content p {
        margin: 4px 0;
    }

.card-cliente-actions {
    position: absolute;
    top: 16px;
    right: 16px;
}

    .card-cliente-actions .btn-delete {
        background: none;
        border: none;
        color: #dc3545;
        cursor: pointer;
        font-size: 1.2em;
    }

        .card-cliente-actions .btn-delete:hover {
            color: #c82333;
        }

.controls {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 16px;
}

.search-form {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.search-input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    flex-grow: 1;
}

.pagination-controls {
    display: flex;
    gap: 10px;
}

.btn-primary {
    padding: 8px 12px;
    background-color: #007bff;
    border: none;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    text-decoration: none;
    font-size: 1em;
}

    .btn-primary:hover {
        background-color: #0056b3;
    }

.error {
    color: red;
    transition: opacity 0.5s ease;
    opacity: 1;
}

    .error.hidden {
        opacity: 0;
    }

.loading-message,
.end-message {
    text-align: center;
    font-size: 1em;
    color: #555;
    margin: 16px 0;
}

.search-input-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.search-input {
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 4px 0 0 4px;
}

.search-btn {
    padding: 11px;
    border: none;
    background-color: #007bff;
    color: #fff;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .search-btn:hover {
        background-color: #0056b3;
    }

    .search-btn .fa-search {
        font-size: 1rem;
    }

/* Media queries for responsive design */
@media (max-width: 768px) {
    .card-cliente-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 480px) {
    .card-cliente-container {
        grid-template-columns: 1fr;
    }
}
