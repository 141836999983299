
body {
    padding-top: 35px;
    width: 100%;
}

.page-container {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding: 0;
    overflow-x: hidden;
}

.container {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-right: 0;
    margin-left: 0;
}

.btn-primary {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 1rem;
}

.btn-primary:hover {
    background-color: orangered;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    width: 100%;
}

.form-group label {
    margin-bottom: 5px;
    font-size: 0.9rem;
}

    .form-group select,
    .form-group input[type="text"],
    .form-group input[type="email"],
    .form-group input[type="password"],
    .form-group input[type="tel"],
    .form-group input[type="cep"],
    .form-group input[type="listbox"],
    .form-group input[type="date"],
    .form-group input[type="checkbox"] {
        padding: 8px;
        border: 1px solid #ddd;
        box-shadow: 2px 2px 1px #ddd;
        background-color: #ffffff;
        outline: none;
        font-size: 1rem;
        box-sizing: border-box;
    }

    .form-group select::placeholder,
    .form-group input[type="text"]::placeholder,
    .form-group input[type="email"]::placeholder,
    .form-group input[type="password"]::placeholder,
    .form-group input[type="tel"]::placeholder,
    .form-group input[type="cep"]::placeholder,
    .form-group input[type="listbox"]::placeholder,
    .form-group input[type="date"]::placeholder,
    .form-group input[type="checkbox"]::placeholder {
        color: gray; 
        font-size: 0.8rem; 
        opacity: 0.75; 
    }

.form-group button {
    padding: 10px;
    margin-top: 10px;
    width: 100%;
}

@media (max-width: 767px) {
    .btn-primary {
        padding: 10px 15px;
        font-size: 0.8rem;
    }
}

@media (max-width: 600px) {
    .wellcome-image,
    .wellcome-div {
        display: none;
    }

    .wellcome-div-dir {
        width: 100%;
        align-content: center;
        padding: 5%;
    }

    .form-group input,
    .form-group button {
        width: 100%;
        box-sizing: border-box;
    }

    .btn-primary {
        padding: 8px 15px;
        font-size: 0.8rem;
    }
}
