.alert-modal {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 80vh;
}

.alert-modal-dialog {
    max-width: 20%;
    max-height: 40%;
    margin-right: 10px;
    border: none;
}

.modal-content {
    background-color: transparent !important;
    border: none;
}

.alert-modal-body-container {
    position: relative;
}

.alert-modal-body {
    border-radius: 10px;
    padding: 10px 10px 5px 10px;
    max-height: 70%;
    overflow-y: auto;
    position: relative;
    text-align: right;
    border: none;
}

.alert-modal-success {
    background-color: #c9e0c0;
}

.alert-modal-error {
    background-color: #f78f48;
    color: white;
    font-weight: 650;
}

.alert-modal-warning {
    background-color: #fff3cd;
    color: #856404;
}

.alert-modal-question {
    background-color: #94a9e6;
    color: #000000;
    font-weight: 600;
}

.alert-modal-default {
    background-color: #f8f9fa;
    color: #6c757d;
}

.alert-image {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 10px auto;
}

.alert-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    transition: background-color 0.3s ease, transform 0.3s ease;
    display: inline-block;
    margin: 10px 0 10px 10px;
}

    .alert-button:first-child {
        margin-left: 0;
    }

    .alert-button:hover {
        background-color: orangered;
        color: white;
        transform: scale(1.05);
    }

    .alert-button:focus {
        outline: none;
        box-shadow: 0 0 0 0.2rem rgba(0,123,255,0.5);
    }

    .alert-button:active {
        background-color: #004085;
        color: white;
    }

.alert-body-success,
.alert-body-error,
.alert-body-warning,
.alert-body-question,
.alert-body-default {
    text-align: left;
}

@media (max-width: 900px) {
    .alert-modal-dialog {
        max-width: 40%;
        max-height: 50%;
    }
}

@media (max-width: 450px) {
    .alert-modal {
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        height: 80%;
    }

    .alert-modal-dialog {
        display: flex;
        justify-content: center;
        width: 100%;
        max-width: none;
        max-height: 60%;
        margin: auto;
    }

    .modal-dialog {
        display: flex;
        justify-content: center;
        width: 95%;
    }
}
