.intelligence-section {
    background-color: lightgray;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 80px 100px;
    color: #1b1b1b;
    position: relative;
}

    .intelligence-section h1 {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 5rem;
        font-weight: 400;
        margin-bottom: 10px;
        letter-spacing: 4px;
    }

    .intelligence-section h2 {
        font-size: 1.8rem;
        font-weight: 200;
        margin-bottom: 20px;
    }

    .intelligence-section h3 {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 30px;
    }

    .intelligence-section h4 {
        font-size: 1.4rem;
        font-weight: 200;
    }

    .intelligence-section p {
        font-size: 1.2rem;
        text-align: justify;
    }

    .intelligence-section span {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 1.2rem;
        color: darkcyan;
        letter-spacing: 10px;
    }

.inteli-cards-container {
    display: flex;
    justify-content: center;
    gap: 2vw;
    margin-top: 30px;
    flex-wrap: wrap;
}

.inteli-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Distribui o conteúdo entre o topo e o fundo */
    width: 31%;
    min-height: 35vh; /* Garante que o card tenha uma altura mínima */
    padding: 20px;
    border-radius: 25px;
    text-align: center;
    color: #fff;
    font-size: 1rem;
    position: relative;
}

.inteli-dark-card {
    background-color: #1c2e36;
}

.inteli-orange-card {
    background-color: #dc920b;
}

.card-quote {
    font-style: italic;
    margin-bottom: 20px;
    text-align: center;
    flex-grow: 1; /* Permite que o texto expanda o card */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centraliza verticalmente */
    margin-bottom: 50px; /* Ajuste para criar mais espaço acima do card-author */
}

.card-author {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
    text-align: right;
    position: absolute;
    right: 35px;
    bottom: 35px;
}

.card-reference {
    font-size: 0.9rem;
    color: #0056b3;
    text-align: right;
    position: absolute;
    right: 35px;
    bottom: 20px;
    font-weight: bold;
    text-decoration: none;
}

    .card-reference:hover {
        text-decoration: underline;
    }

@media (max-width: 600px) {

    .intelligence-section {
        padding: 20px 30px !important;
    }

        .intelligence-section h4 {
            font-size: 1.8rem;
        }

    .inteli-cards-container {
        gap: 20px;
    }

    .inteli-card {
        width: 100%;
        padding: 20px;
    }
}