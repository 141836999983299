@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.new-event {
    animation: fadeIn 1s ease-out;
}

.changed-event {
    animation: fadeIn 1s ease-out;
}

.unchanged-event {
    animation: fadeIn 1s ease-out;
}

.fc-theme-standard {
    background-color: #f0f0f0;
    color: #333;
}

.fc-header-toolbar {
    margin-bottom: 0 !important;
}

.fc .fc-toolbar {
    background-color: #f0f0f0;
    color: #333;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 0;
    position: sticky;
    top: 8px;
    z-index: 999;
    margin-bottom: 0;
    flex-wrap: wrap; /* Adicionado para permitir a quebra de linha */
}

    .fc .fc-toolbar h2 {
        font-size: 1.5em;
        font-weight: bold;
    }

    .fc .fc-toolbar button {
        background-color: #fff;
        color: #333;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 5px 10px;
    }

        .fc .fc-toolbar button:hover {
            background-color: #e0e0e0;
        }

        .fc .fc-toolbar button:disabled {
            background-color: #fff;
            color: #ccc;
        }

.fc .fc-event {
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
}

.fade-in-event {
    animation: fadeIn 1s ease-out;
    background-color: #444;
    color: #fff;
}

.fc {
    max-height: 89.5vh;
    overflow-y: auto;
}

    .fc .fc-scrollgrid-liquid {
        height: 85%;
    }

    .fc .fc-col-header-cell-cushion {
        display: flex;
        font-size: 0.75em;
        color: #777;
    }

        /* Remove o sublinhado e qualquer borda inferior nos dias da semana */
        .fc .fc-col-header-cell-cushion a,
        .fc .fc-col-header-cell-cushion a:hover,
        .fc .fc-col-header-cell-cushion a:focus {
            text-decoration: none;
            border-bottom: none;
        }

        /* Também pode ser necessário remover o sublinhado de outros elementos */
        .fc .fc-col-header-cell-cushion,
        .fc .fc-col-header-cell-cushion * {
            text-decoration: none !important;
            border-bottom: none !important;
        }

    /* Ajustar a margem e o padding do cabeçalho dos dias */
    .fc .fc-col-header {
        margin-top: 0; /* Remover margem superior */
        padding-top: 0; /* Remover padding superior */
    }

    .fc .fc-col-header-cell {
        padding: 0; /* Remover padding interno */
        margin: 0; /* Remover margem */
    }

    .fc .fc-col-header-cell-cushion {
        padding: 5px; /* Ajustar o padding conforme necessário */
    }

    /* Remover bordas */
    .fc .fc-col-header,
    .fc .fc-col-header-cell,
    .fc .fc-col-header-cell-cushion {
        border: none;
    }

    /* Ajuste o tamanho das linhas de horário */
    .fc .fc-timegrid-slot {
        height: 50px; /* Ajuste conforme necessário */
    }

    /* Ajuste o tamanho do texto das horas */
    .fc .fc-timegrid-axis-cushion {
        font-size: 1em; /* Ajuste conforme necessário */
        padding: 10px 0; /* Ajuste conforme necessário */
    }

    .fc .fc-timegrid-col.fc-day-sat {
        background-color: #cedbcc; /* Cor mais clara para sábado */
    }

    .fc .fc-timegrid-col.fc-day-sun {
        background-color: #e0e0e0; /* Cor mais escura para domingo */
    }

/* Estilos responsivos para a barra de ferramentas */
@media (max-width: 768px) {
    .fc-toolbar.fc-header-toolbar {
        display: flex;
        flex-wrap: wrap;
    }

    .fc-toolbar .fc-left,
    .fc-toolbar .fc-center,
    .fc-toolbar .fc-right {
        flex: 1 1 100%;
        text-align: center;
    }

    .fc-toolbar .fc-center {
        order: -1; /* Título vai para a primeira linha */
    }

    .fc-toolbar .fc-left {
        order: -2;
    }

    .fc-toolbar .fc-right {
        order: -3;
    }
}
