
.products-section {
    color: #1b1b1b;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 80px 100px;
    position: relative;
}

    .products-section h1 {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 5rem;
        font-weight: 400;
        margin-bottom: 10px;
        letter-spacing: 4px;
    }

    .products-section h2 {
        font-size: 1.8rem;
        font-weight: 200;
        margin-bottom: 20px;
    }

    .products-section b {
        color: #dc920b;
    }

    .products-section span {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 1.2rem;
        color: darkcyan;
        letter-spacing: 10px;
    }

    .products-section span {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 1.2rem;
        color: darkcyan;
        letter-spacing: 10px;
    }

.product-card-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 50px;
}

.product-card-container {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding: 10px 0;
    scroll-snap-type: x mandatory;
    width: 100%;
}

.product-card {
    position: relative;
    width: 23vw;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    background-color: #1c2e36;
    color: #fff;
    height: 52vh;
    scroll-snap-align: start;
}

.product-card-image {
    width: 100%;
    height: 55%;
    object-fit: cover;
}

.product-card-content {
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 45%;
    background-color: #1c2e36;
}

    .product-card-content h2 {
        margin: 0;
        font-size: 1.4rem;
    }

    .product-card-content p {
        flex-grow: 1;
        font-size: 1rem;
    }

    .product-card-content button {
        align-self: flex-start;
        border: none;
        padding: 10px 15px;
        font-size: 1rem;
        color: #fff;
        background-color: #dc920b;
        border-radius: 5px;
        cursor: pointer;
    }

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    cursor: pointer;
    z-index: 2;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.arrow-left {
    left: 0;
}

.arrow-right {
    right: 0;
}

@media (max-width: 600px) {

    .arrow {
        display: none; 
    }

    .products-section {
        padding: 20px 30px;
    }
        .products-section h1 {
            font-size: 3rem;
            font-weight: 500;
        }

        .products-section h2 {
            font-size: 1.5rem;
            font-weight: 200;
            margin-bottom: 20px;
        }

        .products-section span {
            letter-spacing: 8px;
        }

    .product-card-container {
        gap: 0;
        padding: 10px 5px;
    }

    .product-card {
        width: 90%;
        margin-right: 10px;
    }

    .product-card-wrapper {
        width: 100%;
        padding: 0;
    }
}
