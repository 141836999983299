.form-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
    width: 100%;
    height: 90vh;
    background: linear-gradient(45deg, #e0f5ff, #e2e2e2);
}

.image-container {
    flex: 1;
    max-width: 50%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.form-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;
}

.form-content-child {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.wellcome-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
}

@media (max-width: 768px) {
    .image-container {
        display: none;
    }

    .form-content {
        height: 100%;
        padding: 0;
    }

    .form-content-child {
        padding: 0;
        width: 100%;
        height: 100%;
    }

}
