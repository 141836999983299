.contexto-page-container {
    padding: 20px;
    background-color: #f1f3f5; /* Um cinza claro para um visual mais suave */
    font-family: 'Roboto', sans-serif; /* Roboto para um toque mais moderno */
    color: #343a40; /* Cor de texto um pouco mais escura */
}

.contexto-sections-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.contexto-group {
    margin-bottom: 20px;
    width: 100%;
    max-width: 900px;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Sombra mais sutil */
    transition: transform 0.2s ease; /* Animação de hover */
}

    .contexto-group:hover {
        transform: scale(1.02); /* Leve aumento ao hover */
    }

.contexto-group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #007bff; /* Azul mais vibrante */
    color: #fff;
    padding: 15px 20px;
    cursor: pointer;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    transition: background-color 0.3s ease; /* Suavizando a transição de hover */
}

    .contexto-group-header:hover {
        background-color: #0056b3;
    }

.contexto-group h5 {
    margin: 0;
    font-size: 1.4em; /* Tamanho maior para destaque */
}

.contexto-group-title {
    margin-left: 10px;
}

.contexto-items {
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.3s ease-out;
    padding: 0 20px;
    background-color: #f8f9fa;
}

    .contexto-items.collapsed {
        max-height: 0;
        opacity: 0;
    }

    .contexto-items.expanded {
        max-height: none;
        opacity: 1;
        padding-bottom: 20px;
    }

/* Estilo para cada item de contexto */
.contexto-item {
    display: grid;
    grid-template-columns: 1fr 1fr auto;
    gap: 20px;
    padding: 15px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    align-items: center;
    position: relative;
    margin-top: 10px;
}

    .contexto-item label {
        margin-top: 5px;
        font-weight: 600; /* Textos mais fortes */
    }

    .contexto-item input[type="text"] {
        padding: 10px;
        width: 100%;
        border: 1px solid #ced4da;
        border-radius: 4px;
        transition: border-color 0.3s ease, box-shadow 0.3s ease;
    }

        .contexto-item input[type="text"]:focus {
            outline: none;
            border-color: #007bff;
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Brilho suave ao focar */
        }

.contexto-item-checkbox-row {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contexto-item-checkbox {
    display: flex;
    align-items: center;
}

    .contexto-item-checkbox input[type="checkbox"] {
        margin-left: 5px;
        width: auto;
    }

.contexto-btn-group {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.contexto-btn {
    padding: 10px 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    font-size: 0.9em;
}

.contexto-btn-success {
    background-color: #28a745;
    color: white;
    border: none;
    cursor: pointer;
}

    .contexto-btn-success:hover {
        background-color: #218838;
    }

.contexto-btn-danger {
    background-color: #dc3545;
    color: white;
    border: none;
    cursor: pointer;
}

    .contexto-btn-danger:hover {
        background-color: #c82333;
    }

/* Responsividade */
@media (max-width: 768px) {
    .contexto-item {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .contexto-page-container {
        padding: 10px;
    }

    .contexto-btn-group {
        justify-content: flex-start;
    }
}
