.lateral-image {
    width: 100%;
    max-height: 380px;
    object-fit: cover;
}

.sections-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
    gap: 20px;
    padding: 0 10px;
}

.card-assit {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 1rem;
    background-color: #d8daf2e6;
    position: relative;
}

.card-assit-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
}

.card-assit-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px 20px 5px;
    background-image: linear-gradient(to left, black, transparent);
    color: white;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    max-height: 45%;
}

    .card-assit-content h2 {
        margin-bottom: 0;
        color: darkgray;
    }

    .card-assit-content p {
        margin-top: 0;
    }

.status-select {
    background-color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    margin-right: 10px;
    cursor: pointer;
}

/* Media queries para ajustes em telas pequenas */
@media (max-width: 600px) {
    .intelligence-section {
        padding: 0 0;
    }

    .sections-container {
        padding: 5px 5px; /* Ajuste de padding para telas pequenas */
    }

    .intelligence-section .col {
        padding: 0px; /* Reduzir padding das colunas em telas pequenas */
        min-width: 100%;
    }
}
