.login-container {
    display: flex;
    align-items: stretch;
    justify-content: center;
    height: 85vh;
    margin: 0;
    background-color: #f5f5f5;
}

.login-form-container {
    flex: 1;
    max-width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box; 
}

.login-form {
    width: 100%;
    background: white;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    border-radius: 8px;
    box-sizing: border-box; 
}

.form-group {
    margin-bottom: 20px;
}

input[type="email"],
input[type="text"],
input[type="password"],
input[type="checkbox"] {
    width: 100%;
}

label {
    display: block;
    margin-bottom: 5px;
}

.login-button, .forgot-password {
    width: 100%;
}

.login-button {
    background-color: #0056b3;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.forgot-password {
    text-align: center;
    color: #0056b3;
    text-decoration: none;
    display: block;
    margin-top: 15px;
}

.image-side {
    flex: 1;
    background: url('https://picsum.photos/1024/768?business') no-repeat center center;
/*    background: url('https://source.unsplash.com/random/1024x768?business') no-repeat center center;*/
    background-size: cover;
    min-height: 0;
}

.countdown-button {
    transition: background-color 5s ease-in-out;
}

    .countdown-button:disabled {
        background-color: red;
    }

@media (max-width: 768px) {
    .login-container {
        flex-direction: column;
        align-items: stretch;
    }

    .image-side {
        order: -1; 
        height: 200px;
    }

    .login-form-container {
        width: 100%;
        max-width: none;
        padding: 20px;
    }
}
