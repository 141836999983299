.home-user-container {
    display: flex;
    flex-direction: column;
    padding: 20px ;
}

.text-primary { color: blue; }
.text-danger { color: red; }
.text-success { color: green; }
.text-info { color: teal; }
.text-warning { color: orange; }
.center-text { text-align: center; }

.flex-container {
    display: flex;
    flex-wrap: wrap;
}
.flex-item { flex: 50%; padding: 5px; }

.sections-menu {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Mínimo de 200px para manter o aspecto quadrado */
    gap: 20px;
}


.menu-wrapper {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    transition: transform 0.2s;
    height: 100%;
}

.menu-wrapper:hover {
    transform: scale(1.02);
}

.menu {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    background-color: #d8daf2e6;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    aspect-ratio: 1 / 1; /* Assegura que o card seja quadrado */
    max-height: 35vh;
}

.menu-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
}

.menu-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 20px;
    background-image: linear-gradient(to left, black, transparent);
    color: white;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-height: 20%;
}

.clickable {
    cursor: pointer;
}

/* Mobile em pé: 1 frame */
@media (max-width: 600px) and (orientation: portrait) {
    .sections-menu {
        grid-template-columns: 1fr;
    }
}

/* Mobile deitado: 2 frames */
@media (max-width: 810px) and (orientation: landscape) {
    .sections-menu {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* iPad Mini em pé: 2 frames */
@media (min-width: 601px) and (max-width: 768px) and (orientation: portrait) {
    .sections-menu {
        grid-template-columns: repeat(2, 1fr);
    }
}

/* iPad Mini deitado: 3 frames */
@media (min-width: 1024px) and (max-width: 2024px) and (orientation: landscape) {
    .sections-menu {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* Desktop */
@media (min-width: 1025px) {
    .sections-menu {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}
