
.card-ctrlassit {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    background-color: #d8daf2e6;
    padding: 10px 10px 10px 10px;
    margin-bottom: 20px;
}
