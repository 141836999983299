.cadastro-container {
    display: flex;
    flex-direction: row;
}

.cadastro-menu {
    width: 200px; 
    background-color: #fff;
    padding: 10px 0; 
    border-right: 1px solid #ddd;
    transition: width 0.3s;
}

.cadastro-header {
    display: flex;
    align-items: center;
    padding: 0 10px; 
    margin-bottom: 10px; 
}

.cadastro-avatar {
    width: 50px;
    height: 40px;
    background-color: #e96507;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px; /* Reduzido para ser mais compacto */
    font-weight: bold;
    color: #fff;
    margin-right: 10px; /* Mantido para espaçamento adequado */
}

.cadastro-welcome {
    font-size: 14px; /* Reduzido para ser mais compacto */
    font-weight: normal;
    color: #333;
}

.cadastro-menu-item {
    padding: 10px 10px; /* Reduzido para diminuir espaço */
    cursor: pointer;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
    font-family: Arial, sans-serif;
    border-radius: 10px 0 0 10px;
    margin: 5px 0; /* Alinhamento vertical sem margin-left */
    transition: background-color 0.3s;
    position: relative; /* Para permitir a criação de abas */

}

    .cadastro-menu-item::before {
        content: '';
        position: absolute;
        top: 0;
        left: -10px;
        width: 15px;
        height: 100%;
        background-color: #fff;
        border-radius: 10px 0 0 10px;
        transition: background-color 0.3s;
    }

    .cadastro-menu-item:hover,
    .cadastro-menu-item.active {
        background-color: #f5f5f5;
    }

    .cadastro-menu-item:hover::before,
    .cadastro-menu-item.active::before {
        background-color: #dcdcdc;
        }

    .cadastro-menu-item svg {
        margin-right: 10px;
        color: #666;
    }

.cadastro-menu-text {
    display: flex;
    flex-direction: column;
}

.cadastro-menu-title {
    font-size: 14px;
    font-weight: bold;
    color: #333;
}

.cadastro-menu-description {
    font-size: 12px;
    color: #999;
}

.cadastro-content {
    flex-grow: 1;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
}

/* Responsividade */
@media (max-width: 768px) {
    .cadastro-container {
        flex-direction: row;
    }

    .cadastro-content {
        padding: 10px;
    }
    
    .cadastro-menu {
        width: 15%; /* Ajustado para ser mais visível em dispositivos móveis */
        min-width: 50px; /* Ajustado para ser mais compacto */
        border-right: none;
        border-bottom: 1px solid #ddd;
    }

    .cadastro-menu-item {
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-left: 0;
        padding: 10px 10px 5px; /* Aumentado para ser mais visível */
    }

        .cadastro-menu-item::before {
            left: 0;
            width: 0;
            height: 0;
            border: none;
            border-radius: 0;
        }

        .cadastro-menu-item svg {
            margin-right: 0;
            margin-bottom: 5px;
            font-size: 24px; /* Aumentado para ser mais visível */
        }

    .cadastro-menu-text {
        align-items: center;
    }

    .cadastro-menu-title {
        display: none;
    }

    .cadastro-menu-description {
        display: none;
    }

    .cadastro-header {
        display: none;
    }
}
