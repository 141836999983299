
.cliente-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.form-group {
    flex: 1;
    min-width: calc(50% - 20px);
}

@media (max-width: 768px) {
    .form-group {
        min-width: 100%;
    }
}
