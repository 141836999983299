header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1030; 
    background-color: white; 
    width: 100%;
}

.navbar {
    margin-bottom: 0 !important; 
    padding: 1vh 3vw;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.navbar-brand img {
    height: 40px; 
}

.custom-tooltip .tooltip-inner {
    background-color: #add8e6; 
    color: white; 
    font-weight: bold; 
    font-size: 1.1em; 
}

.custom-tooltip .arrow::before {
    border-bottom-color: #add8e6;
}
