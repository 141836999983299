
.topchef-typewriter-container {
    width: 100%;
    overflow: hidden;
    word-wrap: break-word; 
}

.topchef-typewriter-cursor::after {
    content: '_';
    animation: topchef-blink 0.5s infinite;
    font-weight: 900;
}

@keyframes topchef-blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
