
.usu-content-child {
    display: block;
    width: 95%;
    height: 90%;
}


.tab-content {
    margin-top: 15px; /* Reduzir ou eliminar a margem conforme necessário */
}

.tab-pane {
    min-height: 50vh; 
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.tab-pane > * {
    margin-bottom: 16px; /* Espaçamento entre elementos */
}

.nav-tabs-custom {
    display: flex;
    justify-content: space-around; /* Distribuir guias uniformemente */
    margin-bottom: 0; /* Eliminar espaço abaixo das guias */
    width: 100%; /* Garantir que as guias ocupem toda a largura */
}

.nav-tabs-custom .nav-item {
    flex: 1; /* Cada guia ocupa o mesmo espaço */
    text-align: center; /* Centralizar o texto */
}

.nav-tabs-custom .nav-link {
    display: block;
    width: 100%;
    padding: 10px 15px;
    margin-right: 0;
    border: 1px solid transparent;
    border-radius: 0;
    background-color: #f8f9fa;
}

.nav-tabs-custom .nav-link.active {
    background-color: #007bff;
    color: white;
}

.nav-tabs-custom .nav-link.disabled {
    background-color: #e9ecef;
    color: #6c757d;
    cursor: not-allowed;
}
