
.skeleton-loader-home {
    width: 100%;
    height: 88vh;
    background: linear-gradient(90deg, #bfbfbf 25%, #e2e2e2 50%, #bfbfbf 75%);
    background-size: 200% 100%;
    animation: loading 1.5s infinite;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }

    100% {
        background-position: -200% 0;
    }
}

.carousel-container {
    width: 100%;
    height: 88vh;
    position: relative;
}

.carousel-item {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 1rem;
    position: relative;
}

.carousel-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.slick-dots {
    position: absolute;
    bottom: -10px;
    width: 100%;
    text-align: center;
    z-index: 3;
}

    .slick-dots li button:before {
        color: white;
    }

.carousel-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10vh 8vw 10vh 5vw;
    color: white;
    text-align: left;
    z-index: 2;
    display: flex;
    flex-direction: column;
    transform: translateX(-100%);
    transition: transform 0.5s ease-in, opacity 0.5s ease-in;
    overflow: hidden;
    opacity: 0;
    width: 45%;
    clip-path: url(#curvedClipPath);
}

    .carousel-content.show {
        transform: translateY(0);
        opacity: 1;
    }

    .carousel-content.fade-out {
        opacity: 0;
        transform: translateX(-100%);
    }

.carousel-text-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;
}

.typewriter-container {
    min-height: 50px;
    overflow: hidden;
}

.carousel-header-container {
    margin-bottom: 0;
}

.carousel-content h2 {
    font-size: 3.5rem;
}

.carousel-content span {
    font-size: 1.4rem;
}

.slider-button-container {
    flex: 0 1 auto;
    display: flex;
    align-items: center;
}

.slider-button {
    padding: 10px 25px !important;
    font-size: 1.4rem !important;
    white-space: nowrap;
}

@media (max-width: 600px) {

    .carousel-container {
        width: 100%;
        height: 80vh;
        position: relative;
    }

    .carousel-content {
        top: 50vh;
        padding: 10px 10px 20px 10px;
        color: white;
        text-align: left;
        z-index: 2;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        transform: translateY(100%);
        transition: transform 0.5s ease-in, opacity 0.5s ease-in;
        overflow: hidden;
        opacity: 1;
        clip-path: none;
        height: 32vh;
        width: 100%;
    }

        .carousel-content h2 {
            margin-bottom: 0;
            font-size: 2.5rem;
        }
        .carousel-content span {
            font-size: 1.2rem;
        }

        .carousel-content.show {
            transform: translateY(0);
            opacity: 1;
        }

        .carousel-content.fade-out {
            opacity: 0;
            transform: translateY(100%);
        }

    .carousel-text-container {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        max-width: 85%;
    }

    .slider-button-container {
        width: 100%;
        margin-left: 0;
        margin-bottom: 5px;
    }

    .slider-button {
        width: auto;
        align-self: flex-start;
    }

    .typewriter-container {
        order: 1;
        min-height: 75px;
    }
}

@media (max-width: 1000px) {
    .typewriter-container {
        min-height: 10vh;
    }
}

.typewriter-cursor::after {
    content: '_';
    animation: blink 0.5s infinite;
    font-weight: 900;
}

@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
