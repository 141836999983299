.topsecretario-page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.topsecretario-header-section {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 88vh;
    padding: 4vh 8vw;
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.55);
}

.topsecretario-background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.topsecretario-impact-text-container {
    position: absolute;
    bottom: 0;
    left: 8vw;
    z-index: 2;
    background-color: rgba(28, 46, 54, 0.75);
    color: #fff;
    padding: 20px 25px;
    border-radius: 10px 10px 0 0;
    width: 30%;
    height: 42vh;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: block;
    align-items: center;
    justify-content: center;
    animation: fadeInUp 0.8s forwards;
    opacity: 0;
}
    .topsecretario-impact-text-container h2 {
        color: #cb6400;
        font-weight: 700;
    }

    .topchef-typewriter-cursor {
        font-size: 1.0rem;
    }

.typing-text {
    color: #fff;
}

.topsecretario-functionalities {
    width: 85vw;
    background-color: #fff;
    padding: 50px 200px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    text-align: center;
    border-radius: 10px;
}

.functionalities-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.functionality-item {
    perspective: 1000px;
    width: 100%;
    height: 250px;
    padding: 0;
    background: transparent;
    box-shadow: none;
    border: none;
}

.functionality-card {
    position: relative;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.6s;
}

.functionality-item:hover .functionality-card {
    transform: rotateY(180deg);
}

.functionality-card-front, .functionality-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
}

.functionality-card-back {
    background-size: cover;
    background-position: center;
    transform: rotateY(180deg);
}

.topsecretario-giant-button {
    padding: 20px 60px;
    font-size: 20px;
    background-color: #ff8c00;
    color: #fff;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

    .topsecretario-giant-button:hover {
        background-color: #e67600;
        transform: scale(1.05);
    }

.topsecretario-form-container {
    width: 40vw;
    background-color: #fff;
    padding: 60px 80px 40px 80px;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    margin: 40px 0;
    text-align: left;
    gap: 20px;
}

.usuario-form {
    display: flex;
    flex-direction: column;
    gap: inherit;
}

.form-row {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.form-group {
    flex: 1;
    min-width: 200px;
}

.form-control {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 5px;
}

    .form-control:focus {
        outline: none;
        border-color: #838dd8;
    }

.usuario-form button {
    padding: 15px 30px;
    font-size: 18px;
    background-color: #ff8c00;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

    .usuario-form button:hover {
        background-color: #e67600;
    }

.topSecretario-description-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.topSecretario-description-section {
    display: flex;
    align-items: flex-start;
    margin: 20px 0;
    padding: 50px;
    width: 100%;
    position: relative;
    z-index: 2;
}

.topSecretario-description-image {
    width: 38vw;
    height: auto;
    margin: 0 20px;
    border-radius: 12px;
    flex-shrink: 0;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 3;
}

.topSecretario-description-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2vw 4vw;
}

.topSecretario-description-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.2;
    margin-bottom: 10px;
    text-align: left;
}

.topSecretario-description-text {
    font-size: 18px;
    line-height: 2;
    text-align: left;
}

.topSecretario-transition-decorator {
    display: flex;
    align-items: center;
    width: 100%;
    height: 150px;
    position: relative;
    margin: -75px 0;
    z-index: 1;
    justify-content: center;
}

.topSecretario-ellipse {
    position: absolute;
    z-index: 1;
}

    .topSecretario-ellipse.round {
        width: 40vw;
        height: 40vw;
        margin-top: 35vh;
        background: rgba(21, 120, 8, 0.70);
        border-radius: 50%;
    }

    .topSecretario-ellipse.stretched {
        margin-top: 20vh;
        width: 65%;
        height: 30vh;
        background: rgba(237, 115, 23, 0.80);
        border-radius: 35px;
    }

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .topsecretario-header-section,
    .topsecretario-description-container,
    .topsecretario-functionalities {
        padding: 20px;
    }

    .topsecretario-description-container {
        padding: 0;
    }

    .topsecretario-impact-text-container {
        width: 85vw;
        height: 38vh;
        border-radius: 10px 10px 0px 0px;
        transform: translate(-50%, -50%);
        animation: fadeInUp 0.8s forwards;
        opacity: 1;
    }
    .topsecretario-impact-text-container h2 {
        font-size: 2.5rem;
    }

    .topSecretario-description-section {
        width: 100%;
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 0;
        border: none;
    }

    .topSecretario-description-image {
        width: 100%;
        border-radius: 0;
        margin: 0 0 20px 0;
    }

    .topSecretario-description-content {
        padding: 0;
    }

    .topSecretario-description-title,
    .topSecretario-description-text {
        text-align: center;
    }

    .functionality-card-title h4 {
        font-size: 26px;
    }

    .functionality-card-text {
        font-size: 16px;
    }

    .topSecretario-description-title {
        font-size: 28px;
    }

    .topSecretario-description-text {
        font-size: 18px;
    }

    .topSecretario-transition-decorator,
    .topSecretario-ellipse {
        display: none;
    }

    .functionalities-grid {
        grid-template-columns: 1fr;
    }

    .topsecretario-form-container {
        width: 100%;
        max-width: 100%;
        padding: 30px;
    }

    .topsecretario-header-section {
        height: 85vh;
    }

}

.skeleton-loader {
    width: 100%;
    height: 100%;
    background-color: #eaeaea;
    animation: skeleton-loading 1.5s infinite;
    position: absolute;
    top: 0;
    left: 0;
}

@keyframes skeleton-loading {
    0% {
        background-color: #eaeaea;
    }

    50% {
        background-color: #f0f0f0;
    }

    100% {
        background-color: #eaeaea;
    }
}

.functionality-card-front {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    box-sizing: border-box;
    height: 100%;
    background-color: #eaeaea;
    border-radius: 8px;
}

.functionality-card-title {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
    flex-shrink: 0;
}

    .functionality-card-title h4 {
        margin: 0;
    }

.functionality-card-text {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 10px;
    overflow-y: auto;
}

.footer{
    width: 100%;
}