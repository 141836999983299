
.usuario-form button {
    padding: 15px 30px;
    font-size: 18px;
    background-color: #ff8c00;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

    .usuario-form button:hover {
        background-color: #e67600;
    }

.new-user-form-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    max-width: 400px;
    flex: 1;
}

.new-user-form {
    width: 100%;
    background: white;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

    .new-user-form h3 {
        text-align: center;
        color: #333;
    }

    .new-user-form button {
        width: 100%;
        padding: 10px;
        font-size: 18px;
        background-color: #0056b3;
        color: #fff;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        margin-top: 10px;
        transition: background-color 0.3s;
    }

        .new-user-form button:hover {
            background-color: #004494;
        }
