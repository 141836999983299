.conversa-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.card-conversa-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 16px;
    background-color: rgba(65, 65, 65, 0.1);
    min-height: 100%;
}

.card-conversa {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    padding: 0 0;
}

.card-conversa-inner {
    display: flex;
    flex-direction: column;
}

.mensagem-entrada,
.mensagem-saida,
.mensagem-function,
.mensagem-function-entrada,
.mensagem-function-saida {
    border-radius: 8px;
    padding: 10px;
    margin: 5px 0;
    max-width: 75%;
    word-wrap: break-word;
    box-sizing: border-box;
}

.mensagem-function-entrada,
.mensagem-function-saida {
    font-size: 13px;
}

.mensagem-entrada {
    align-self: flex-start;
    background-color: #d0e6ff;
}

.mensagem-saida {
    align-self: flex-end;
    background-color: #ccffcc;
}

.mensagem-function {
    max-width: 100%;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    cursor: pointer; /* Muda o ícone do mouse para indicar clique */
    transition: max-height 0.3s ease, transform 0.3s, background-color 0.3s, color 0.3s; /* Adiciona transições para suavidade */
    overflow: hidden; /* Garante que o conteúdo oculto não seja exibido */
    max-height: 40px; /* Altura padrão quando contraído */
}

.mensagem-function-title {
    align-self: center;
    background-color: #f1f1f1;
    font-size: 12px;
    text-align: center;
    border-radius: 8px;
    padding: 5px 10px;
    font-weight: 600;
    transition: transform 0.3s, background-color 0.3s, color 0.3s; /* Adicionando transições para suavidade */
}

    .mensagem-function-title:hover {
        transform: scale(1.2); /* Aumenta o tamanho em 20% */
        cursor: pointer; /* Muda o ícone do mouse para indicar clique */
        background-color: #e0e0e0; /* Ajuste a cor de fundo para dar mais contraste */
        color: #333; /* Ajuste a cor do texto para dar mais contraste */
    }

.mensagem-function-entrada {
    background-color: #fcf1b4ff;
}

.mensagem-function-saida {
    background-color: #fcf1b4ff;
}

.mensagem-function.expanded {
    max-height: 2000px; /* Define uma altura grande o suficiente para acomodar o conteúdo expandido */
}

.resumo-list {
    display: flex;
    flex-direction: column;
    width: 3vw;
    border-right: 1px solid #ddd;
    padding: 0;
    box-sizing: border-box;
    overflow-y: auto;
}

.resumo-item {
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.3s;
    border-left: 5px solid rgb(137, 179, 133);
    border-right: 5px solid transparent;
    height: 22vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

    .resumo-item.selected {
        background-color: rgba(65, 65, 65, 0.1);
        border-left-color: rgb(59, 139, 50);
        font-weight: 600;
        font-size: 1.15rem;
    }

    .resumo-item:hover {
        background-color: #999;
        color: #fff;
    }

.resumo-inicial {
    display: inline-block;
    transform: rotate(-90deg); 
    transform-origin: center; 
    white-space: nowrap; 
}

@media (max-width: 600px) {
    .resumo-list {
        width: 10vw;
    }
}
