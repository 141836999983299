.recommends-section {
    color: #1b1b1b;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 80px 100px;
    position: relative;
}

    .recommends-section h1 {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 3rem;
        margin-bottom: 10px;
        letter-spacing: 4px;
    }

    .recommends-section h2 {
        font-size: 1.4rem;
        font-weight: 200;
        margin-bottom: 20px;
    }

    .recommends-section b {
        color: #dc920b;
    }

    .recommends-section p {
        font-size: 1.1rem;
    }

    .recommends-section span {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 1.5rem;
        color: darkcyan;
        letter-spacing: 10px;
        margin-bottom: 10px;
    }

    .recommends-section .row {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .recommends-section .fa-quote-right {
        font-size: 5rem;
        color: white;
        background-color: #dc920b;
        margin-bottom: 10px;
        border-radius: 50%;
        width: 120px;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
    }


.recommend-card-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 50px;
}

.recommend-card-container {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding: 50px 0 20px 0;
    scroll-snap-type: x mandatory;
    width: 100%;
    height: 72vh;
}

.recommend-card {
    position: relative;
    width: 24vw;
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    color: #fff;
    height: 60vh;
    scroll-snap-align: start;
    padding-top: 50px;
    box-sizing: border-box;
}

.recommend-card-image, .recommend-card-placeholder {
    width: 120px;
    height: 120px;
    object-fit: cover;
    border-radius: 50%;
    position: absolute;
    top: -45px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.recommend-card-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: inherit;
    text-align: center;
}

.recommend-stars {
    font-size: 2.5rem !important;
    color: gold;
    margin: 10px 0;
}

.recommend-description {
    font-size: 1rem;
    color: inherit;
    margin: 10px 0;
    text-align: center;
}

.recommend-name {
    font-size: 0.9rem;
    font-weight: bold;
    color: inherit;
    margin-top: auto;
    padding-bottom: 10px;
    text-align: center;
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    background-color: rgba(255, 255, 255, 0.7);
    border: none;
    cursor: pointer;
    z-index: 2;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.arrow-left {
    left: 0;
}

.arrow-right {
    right: 0;
}

@media (max-width: 600px) {

    .arrow {
        display: none;
    }

    .recommends-section {
        padding: 20px 30px;
    }
        .recommends-section h1 {
            font-size: 2.2rem;
        }

        .recommends-section span {
            font-size: 1.2rem;
        }

    .recommend-card-container {
        gap: 0;
    }

    .recommend-card {
        width: 90%;
        margin-right: 10px;
    }

    .recommend-card-wrapper {
        width: 100%;
        padding: 0;
    }
}
