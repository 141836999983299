.form-group {
    position: relative;
    width: 100%;
}

.search-input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    box-sizing: border-box; /* Garante que o padding não afete a largura */
}

.search-results {
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 100%; /* Faz a lista começar imediatamente após o input */
    left: 0;
    width: 100%; /* A largura da lista corresponde ao input */
    background: white;
    border: 1px solid #ccc;
    border-top: none; /* Remove a borda superior para se conectar visualmente ao input */
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    z-index: 1000;
    max-height: 200px; /* Limita a altura máxima e permite rolagem */
    overflow-y: auto;
}

.search-result-item {
    padding: 10px;
    cursor: pointer;
    display: block; /* Garante que o item seja um bloco para ocupar toda a largura */
}

    .search-result-item.selected, .search-result-item:hover {
        background-color: #f4f4f4;
    }

.user-phone {
    font-size: smaller;
    color: #666;
}
