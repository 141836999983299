
.trust-section {
    background-color: rgb(28, 46, 54);
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 80px 100px;
    position: relative;
}

    .trust-section h1 {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 5rem;
        font-weight: 400;
        margin-bottom: 10px;
        letter-spacing: 4px;
        color: white;
    }

    .trust-section h2 {
        font-size: 1.8rem;
        font-weight: 200;
        margin-bottom: 20px;
        color: white;
    }

    .trust-section p {
        font-size: 1rem;
        text-align: left;
    }

    .trust-section span {
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 1.2rem;
        color: rgb(220, 146, 11);
        letter-spacing: 10px;
    }

    .trust-section b {
        color: rgb(220, 146, 11) !important;
    }

.trust-cards-container {
    display: flex;
    justify-content: center;
    gap: 2vw;
    margin-top: 30px;
    flex-wrap: wrap;
}

.trust-card {
    display: flex;
    align-items: center;
    width: 31%;
    padding: 4% 4%;
    border-radius: 15px;
    text-align: center;
    font-size: 1.5rem;
    border: 2px solid;
    background-color: #1c2e36;
    color: white;
}

.trust-dark-card {
    border-color: white;
}

.trust-orange-card {
    border-color: rgb(220, 146, 11);
}

.trust-card h3 {
    font-size: 4rem;
    color: rgb(220, 146, 11);
    margin: 0;
    margin-right: 15px;
    font-weight: 700;
}

.trust-card p {
    font-size: 1.8rem;
    font-weight: 400;
}

@media (max-width: 600px) {

    .trust-section {
        padding: 20px 30px;
    }
        .trust-section h1 {
            font-size: 4.5rem;
            font-weight: 5600;
        }

        .trust-section span {
            letter-spacing: 8px;
        }


    .trust-cards-container {
        gap: 20px;
    }

    .trust-card {
        width: 100%;
    }
}
